<template>
  <div>
  <form id="personalInformationForm" autocomplete="off">
    <div class="form-group row">
      <label for="firstName" class="col-lg-4 col-md-3 col-sm-4 col-form-label text-sm-right">First Name</label>
      <div class="col-sm-6 col-lg-4">
        <input 
          type="text" 
          id="firstName" 
          v-model="pc.firstName" 
          maxlength="64"
          class="form-control" 
          :disabled="pcHasHousehold()"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="lastName" class="col-lg-4 col-md-3 col-sm-4 col-form-label text-sm-right">Last Name</label>
      <div class="col-sm-6 col-lg-4">
        <input 
          type="text" 
          id="lastName" 
          v-model="pc.lastName" 
          maxlength="64"
          class="form-control" 
          :disabled="pcHasHousehold()"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="zipCode" class="col-lg-4 col-md-3 col-sm-4 col-form-label text-sm-right">Zip Code</label>
      <div class="col-sm-6 col-lg-4">
        <input 
          type="text" 
          id="zipCode" 
          v-model="pc.zip5" 
          maxlength="5"
          class="form-control" 
          :disabled="pcHasHousehold()"
        />
      </div>
    </div>

    <div v-if="!pcHasHousehold()" style="text-align:left">
      <br><router-link :to="{ name: 'SearchRoute' }">Search for your household</router-link>
    </div>

    <div v-else>

      <p style="text-align:left">
        This address reflects the household that you selected after creating your account. 
        If this is no longer correct, please enter a Change of Address below and we will 
        update our records accordingly.
      </p>

      <div class="form-group row">
        <label for="address" class="col-lg-4 col-md-3 col-sm-4 col-form-label text-sm-right">Address</label>
        <div class="col-sm-6 col-lg-4">
          <input 
            type="text" 
            :disabled="true" 
            id="address" 
            v-model="pc.address" 
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="city" class="col-lg-4 col-md-3 col-sm-4 col-form-label text-sm-right">City</label>
        <div class="col-sm-6 col-lg-4">
          <input 
            type="text" 
            :disabled="true" 
            id="city" 
            v-model="pc.city" 
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="state" class="col-lg-4 col-md-3 col-sm-4 col-form-label text-sm-right">State</label>
        <div class="col-sm-6 col-lg-4">
          <input 
            type="text" 
            :disabled="true" 
            id="state" 
            v-model="pc.state" 
            class="form-control"
          />
        </div>
      </div>
      <div class="form-group row">
        <label for="coa" class="col-lg-4 col-md-3 col-sm-4 col-form-label text-sm-right">Change of Address</label>
        <div class="col-sm-6 col-lg-4">
          <textarea 
            id="coa" 
            v-model="pc.coa" 
            maxlength="1024" 
            rows="2" 
            placeholder="Address, City, State Zip" 
            class="form-control"
          />
        </div>
      </div>

      <p style="text-align:left">
        The following demographics reflect the household you selected. If you choose 
        to make corrections, they will be recorded and updated within 30 days.
      </p>

      <DemoSelectComponent label="Number of Adults in Household" :options="numAdultsOptions" v-model="pc.vNumAdults" />
      <DemoSelectComponent label="Adult Age Range 1" :options="ageRangeOptions" v-model="pc.vAgeRange1" />
      <DemoSelectComponent label="Adult Age Range 2" :options="ageRangeOptions" v-model="pc.vAgeRange2" />
      <DemoSelectComponent label="Presence of Children" :options="presenceOfChildrenOptions" v-model="pc.vPresenceOfChildren" />
      <DemoSelectComponent label="Estimated Household Income" :options="incomeOptions" v-model="pc.vIncome" />
      <DemoSelectComponent label="Estimated Household Discretionary Income" :options="discIncomeOptions" v-model="pc.vDiscIncome" />
      <DemoSelectComponent label="Estimated Home Value" :options="homeValueOptions" v-model="pc.vHomeValue" />
      <DemoSelectComponent label="Length of Residence" :options="lengthOfResidenceOptions" v-model="pc.vLengthOfResidence" />
      <DemoSelectComponent label="Vehicle Propensity 1" :options="vehiclePropensityOptions" v-model="pc.vVehiclePropensity1" />
      <DemoSelectComponent label="Vehicle Propensity 2" :options="vehiclePropensityOptions" v-model="pc.vVehiclePropensity2" />
      <DemoSelectComponent label="Voter Registration 1" :options="voterRegistrationOptions" v-model="pc.vVoterRegistration1" />
      <DemoSelectComponent label="Voter Registration 2" :options="voterRegistrationOptions" v-model="pc.vVoterRegistration2" />
    </div>

  </form>
  
    <br>
    <p align="center">
      <button class="btn btn-primary" @click="savePersonalInformation">Save</button>
    </p>
  </div>
</template>

<script>
  import { pcMixin } from '@/mixins/pcMixin';
  import { apiMixin } from '@/mixins/apiMixin.js';
  import { demoMixin } from '@/mixins/demoMixin';
  import DemoSelectComponent from '@/components/DemoSelectComponent';

  export default {
    name: 'PersonalInformationView',

    mixins: [ pcMixin, apiMixin, demoMixin ],

    components: {
      DemoSelectComponent
    },

    methods: {
      savePersonalInformation() {
        this.pcSpinnerShow();
        setTimeout(() => {
          this.pcSpinnerHide();

          this.pcModal("Your changes have been saved")
          .then(() => {
            // Save the personal information.
            if (this.pcHasHousehold()) {
              this.pc.codPending = true; // Set the Change Of Demographics flag.
              this.apiSavePersonalInformation()
              .then(() => {})
              .catch((error) => {
                this.handleError(error)
              })
              .finally(() => {})
            } else {
              // Save just the name and zip fields.
              this.apiSaveNameZip()
              .then(() => {})
              .catch((error) => {
                this.handleError(error)
              })
              .finally(() => {})
            }
          })
          .finally(() => {})
        }, 2000);
      }
    }
  }
</script>
