export const demoMixin = {

  data() {
    return {
      numAdultsOptions: [
        { value: 'A', label: 'One' },
        { value: 'B', label: 'Two'  },
        { value: 'C', label: 'Three' },
        { value: 'D', label: 'Four' },
        { value: 'E', label: 'Five or more' }
      ],

      ageRangeOptions: [
        { value: '', label: 'Unknown' },
        { value: 'A', label: '18-22' },
        { value: 'B', label: '23-29' },
        { value: 'C', label: '30-39' },
        { value: 'D', label: '40-49' },
        { value: 'E', label: '50-59' },
        { value: 'F', label: '60-69' },
        { value: 'G', label: '70-79' },
        { value: 'H', label: '80-89' },
        { value: 'I', label: '90 Plus' },
      ],

      presenceOfChildrenOptions: [
        //{ value: '', label: 'Unknown' },
        { value: 'Y', label: 'Yes' },
        { value: 'N', label: 'No' },
        { value: 'U', label: 'Unknown' }
      ],

      incomeOptions: [
        { value: 'A', label: '<$30K' },
        { value: 'B', label: '$30K-$40K' },
        { value: 'C', label: '$40K-$50K' },
        { value: 'D', label: '$50K-$60K' },
        { value: 'E', label: '$60K-$75K' },
        { value: 'F', label: '$75K-$100K' },
        { value: 'G', label: '$100K-$125K' },
        { value: 'H', label: '$125K-$150K' },
        { value: 'I', label: '$150K-$200K' },
        { value: 'J', label: '$200K-$250K' },
        { value: 'K', label: '$250K-$300K' },
        { value: 'L', label: '$300K-$500K' },
        { value: 'M', label: '>$500K' },
      ],

      discIncomeOptions: [
        { value: '', label: 'Unknown' },
        { value: 'A', label: 'High' },
        { value: 'B', label: 'Above Average' },
        { value: 'C', label: 'Average' },
        { value: 'D', label: 'Minimal' }
      ],

      homeValueOptions: [
        { value: '', label: 'Unknown' },
        { value: 'A', label: '$1K-$25K' },
        { value: 'B', label: '$25K-$50K' },
        { value: 'C', label: '$50K-$75K' },
        { value: 'D', label: '$75K-$100K' },
        { value: 'E', label: '$100K-$125K' },
        { value: 'F', label: '$125K-$150K' },
        { value: 'G', label: '$150K-$175K' },
        { value: 'H', label: '$175K-$200K' },
        { value: 'I', label: '$200K-$225K' },
        { value: 'J', label: '$225K-$250K' },
        { value: 'K', label: '$250K-$275K' },
        { value: 'L', label: '$275-$300K' },
        { value: 'M', label: '$300K-$350K' },
        { value: 'N', label: '$350K-$400K' },
        { value: 'O', label: '$400K-$450K' },
        { value: 'P', label: '$450K-$500K' },
        { value: 'Q', label: '$500K-$775K' },
        { value: 'R', label: '$775K-$1M' },
        { value: 'S', label: '$1M-$2M' },
        { value: 'T', label: '$2M-$3M' },
        { value: 'V', label: '$3M-$5M' },
        { value: 'W', label: '$5M-$10M' },
        { value: 'X', label: '$10M Plus' }
      ],

      lengthOfResidenceOptions: [
        { value: '', label: 'Unknown' },
        { value: 'A', label: '<1 year' },
        { value: 'B', label: '1 year' },
        { value: 'C', label: '2 years' },
        { value: 'D', label: '3 years' },
        { value: 'E', label: '4 years' },
        { value: 'F', label: '5 years' },
        { value: 'G', label: '6 years' },
        { value: 'H', label: '7 years' },
        { value: 'I', label: '8 years' },
        { value: 'J', label: '9 years' },
        { value: 'K', label: '10 years' },
        { value: 'L', label: '>10 years' },
      ],

      vehiclePropensityOptions: [
        { value: '', label: 'Unknown' },
        { value: 'A', label: 'Compact' },
        { value: 'B', label: 'Truck' },
        { value: 'C', label: 'Domestic Sedan' },
        { value: 'D', label: 'Foreign Sedan' },
        { value: 'E', label: 'Luxury Sedan' },
        { value: 'F', label: 'SUV' },
        { value: 'G', label: 'Luxury SUV' },
        { value: 'H', label: 'Hybrid' },
        { value: 'I', label: 'Minivan' },
        { value: 'J', label: 'Sports Car' },
        { value: 'K', label: 'Compact SUV' }
      ],

      voterRegistrationOptions: [
        { value: '', label: 'Unknown' },
        { value: '1', label: 'Democrat' },
        { value: '2', label: 'Republican' },
        { value: '3', label: 'Unaffiliated' },
        { value: '4', label: 'Libertarian' },
        { value: '5', label: 'Green' },
        { value: '6', label: 'Unidentified' },
        { value: '7', label: 'Other' }
      ]
    }
  },

}