<template>
  <div class="form-group row">
    <label class="col-lg-4 col-md-3 col-sm-4 col-form-label text-sm-right">{{ label }}</label>
    <div class="col-sm-6 col-lg-4">
      <select class="form-control" @change="$emit('input', $event.target.value)">
        <option
          v-for="opt in options"
          :key="opt.value"
          :value="opt.value"
          :selected="value === opt.value">
          {{ opt.label || '' }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "DemoSelectComponent",
  props: {
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true,
      validator(opts) {
        return !opts.find(opt => typeof opt !== 'object');
      }
    },
    value: {
      // type: String,
      required: true
    }
  }
}
</script>
